import React, { Component, Suspense } from 'react';
import { Route } from 'react-router';
import './i18n';
import Payment from './components/Payment';
import './App.scss';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Suspense fallback={null}>
                <Route path='/:hash' component={Payment} />
            </Suspense>
        );
    }
}