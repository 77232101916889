import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    IPaymentWithDossierInfo, IResponseResult, IMolliPaymentInfo, IConfiguration,
    IPaymentRequest, IPaymentLinkResult
} from '../type/index';
import PaymentApi from '../api/PaymentApi';
import {
    PrimaryButton, Text, Label, Link, Image, MessageBar, MessageBarType, Shimmer, ShimmerElementType
} from 'office-ui-fabric-react';
import './Payment.scss';
import { PaymentStatus } from 'src/enum';
import { Utilities } from '../utility/Utility';
import LanguageSelector from './languageSelector';
import FoldableGroup from './shared/foldableGroup';

export default function Payment() {
    const { t } = useTranslation();

    let announceData = [
        {
            key: PaymentStatus[PaymentStatus.Paid],
            status: t('paymentReceived'),
            extraInfo: t('paymentThankyou'),
            icon: 'ms-Icon--CheckMark'
        },
        {
            key: PaymentStatus[PaymentStatus.Failed],
            status: t('paymentFailed'),
            extraInfo: t('tryAgain'),
            icon: 'ms-Icon--Important'
        },
        {
            key: PaymentStatus[PaymentStatus.Expired],
            status: t('paymentExpired'),
            extraInfo: t('tryAgain'),
            icon: 'ms-Icon--Important'
        },
        {
            key: PaymentStatus[PaymentStatus.Canceled],
            status: t('paymentCanceled'),
            extraInfo: t('tryAgain'),
            icon: 'ms-Icon--Important'
        },
        {
            key: PaymentStatus[PaymentStatus.Open],
            status: t('paymentUnderProcess'),
            extraInfo: '',
            icon: 'ms-Icon--SignOut'
        }
    ];

    const [paymentWithDossier, setPaymentWithDossier] = React.useState<IPaymentWithDossierInfo>({
        title: '',
        dossierNumber: '',
        clientName: '',
        paymentLink: {
            url: '',
            amount: '',
            hash: '',
            organizationId: ''
        }
    });
    const [configuration, setConfiguration] = React.useState<IConfiguration>({ kolektoEndPoint: '' });
    const [errors, setErrors] = React.useState<string[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    let { hash } = useParams();

    React.useEffect(() => {
        PaymentApi.getClientConfig().then((result: IResponseResult) => {
            if (result) {
                setConfiguration(result.data);
                initData(result.data);
            }
        });
    }, []);

    function initData(conf: IConfiguration) {
        if (!conf || !conf.kolektoEndPoint) {
            return;
        }

        let request: IPaymentRequest = {
            hash: hash,
            endpointUrl: conf.kolektoEndPoint
        };

        if (hash) {
            PaymentApi.GetPaymentInfo(request).then(result => {
                if (!result.error && result.data) {
                    let paymentWithDossier: IPaymentWithDossierInfo = result.data;
                    setPaymentWithDossier(paymentWithDossier);
                } else if (result.messages) {
                    setErrors(result.messages);
                }
                setLoaded(true);
            })
        }
    }

    function renderAnnounce() {
        let avoidStatus = [PaymentStatus.Pending, PaymentStatus.Open],
            failedStatus = [PaymentStatus.Canceled, PaymentStatus.Expired, PaymentStatus.Failed];
        if (paymentWithDossier && paymentWithDossier.paymentLink &&
            paymentWithDossier.paymentLink.paymentStatus &&
            !avoidStatus.includes(paymentWithDossier.paymentLink.paymentStatus)) {

            let data = announceData.find(item => item.key === PaymentStatus[paymentWithDossier.paymentLink.paymentStatus || PaymentStatus.Open])

            return (
                <div className="ms-Grid-row ideal status">
                    <div className="ms-Grid-col ms-sm3">
                        <div className={`circle ${data && data.key}`}>
                            <i className={`ms-Icon ${data && data.icon}`}></i>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm9 no-padding">
                        <div className="info">{data && data.status}</div>
                        <div className="extra-info">
                            {failedStatus.includes(paymentWithDossier.paymentLink.paymentStatus) &&
                                <React.Fragment>
                                    <i className="ms-Icon ms-Icon--ChevronRightSmall"></i>
                                    <Link className="link" href="*" onClick={(event) => {
                                        event.preventDefault();
                                        buttonSubmit();
                                    }}>{data && data.extraInfo}</Link>
                                </React.Fragment>
                            }
                            {!failedStatus.includes(paymentWithDossier.paymentLink.paymentStatus) &&
                                <span>{data && data.extraInfo}</span>
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="ms-Grid-row ideal">
                    <Shimmer isDataLoaded={!submitting} width="30%" shimmerElements={[{ type: ShimmerElementType.line, height: 30, width: 110 }]}>
                        <PrimaryButton className="ideal-button" text={t('payWithIdeal')} onClick={() => buttonSubmit()} />
                    </Shimmer>
                </div>
            )
        }
    }

    function renderFailedPayment() {
        return (
            <div className="ms-Grid-row ideal status">
                <div className="ms-Grid-col ms-sm3">
                    <div className={`circle Failed`}>
                        <i className={`ms-Icon ms-Icon--Important`}></i>
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm9 no-padding">
                    <div className="info">{t('unknowPayment')}</div>
                    <div className="extra-info">
                        {t('unknowPaymentDesc')}
                        <FoldableGroup
                            title={t('moreinfo')}>
                            {errors.map((error) => {
                                return <div>{error}</div>
                            })}
                        </FoldableGroup>
                    </div>
                </div>
            </div>
        )
    }

    function buttonSubmit() {
        if (paymentWithDossier && paymentWithDossier.paymentLink) {
            let request: IMolliPaymentInfo = {
                hash: hash,
                description: paymentWithDossier.paymentLink.description,
                amount: paymentWithDossier.paymentLink.amount,
                paymentId: paymentWithDossier.paymentLink.id,
                organizationId: paymentWithDossier.paymentLink.organizationId
            }
            setSubmitting(true);
            PaymentApi.PostPaymentInfo(request).then(result => {
                if (result.error && result.messages) {
                    setErrors(result.messages);
                } else {
                    updatePaymentLinkInDossier(result);
                }
            })
        }
    }

    function updatePaymentLinkInDossier(result: IResponseResult) {
        if (!result.error && result.data) {
            let paymentLinkResult: IPaymentLinkResult = result.data;
            let request: IPaymentRequest = {
                hash: hash,
                endpointUrl: configuration.kolektoEndPoint,
                transactionId: paymentLinkResult.transactionId
            };

            PaymentApi.updatePaymentInfo(request).then(result => {
                if (!result.error && result.data) {
                    window.location.href = paymentLinkResult.paymentLinkHref;
                } else {
                    setErrors(result.messages || []);
                    setSubmitting(false);
                }
            });
        }
    }

    let { title, paymentLink } = { ...paymentWithDossier };

    return (
        <div className="kolekto-container">
            <LanguageSelector />
            <div className="kolekto-form">
                <div className="kolekto-header">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Shimmer isDataLoaded={loaded} width="45%" shimmerElements={[{ type: ShimmerElementType.line, height: 30 }]}>
                                <Text className="amount" nowrap block>{paymentLink && paymentLink.amount && Utilities.formatMoney(paymentLink.amount, true)}</Text>
                            </Shimmer>
                            <Shimmer isDataLoaded={loaded} className="mT-5">
                                <Text className="title" nowrap block>{errors.length > 0 ? t('unknowPayment') : title}</Text>
                            </Shimmer>
                        </div>
                    </div>
                </div>
                <div className="kolekto-content">
                    {errors.length > 0 && renderFailedPayment()}
                    {errors.length == 0 &&
                        <React.Fragment>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <Label className="description-label">{t('description')}</Label>
                                    <Shimmer isDataLoaded={loaded} className="mT-5">
                                        <Label className="description">{paymentLink && paymentLink.description}</Label>
                                    </Shimmer>
                                </div>
                            </div>
                            {renderAnnounce()}
                        </React.Fragment>
                    }
                </div>
                <div className="kolekto-footer">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm2 image">
                            <Image src="logo.png" width={41} />
                        </div>
                        <div className="ms-Grid-col ms-sm10 ideal">
                            <div><Label className="ideal-description">{t('idealDescription')}</Label></div>
                            <Link className="ideal-link" href="https://www.ideal.nl/consumenten">{t('idealLink')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}