import React from 'react';
import { ActionButton, IButtonStyles } from 'office-ui-fabric-react';

interface IFoldableGroupProps {
    title: string;
    expandByDefault?: boolean;
    children?: any;
    className?: string;
    error?: boolean;
}
const FoldableGroup = (props: IFoldableGroupProps) => {
    const [isExpand, setIsExpand] = React.useState(props.expandByDefault);

    const buttonStyle: IButtonStyles = {
        root: [
            {
                marginLeft: '-8px',
                color: '#323130',
                fontSize: 14,
                fontWeight: 500,
                height: 32
            },

            props.error && {
                color: '#a80000'
            }
        ],
        icon: [
            {
                color: '#8e8d8d',
                fontSize: 14,
                fontWeight: 600,
                marginRight: 5,
                position: 'relative',
                top: 1
            },
            props.error && {
                color: '#a80000'
            }
        ]
    };

    return (
        <div className={`foldable-container ${props.className}`}>
            <ActionButton
                className="foldable-group"
                styles={buttonStyle}
                onClick={() => setIsExpand(!isExpand)}>
                <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
            </ActionButton>
            <div className={!isExpand ? 'hidden' : ''}>
                {props.children}
            </div>
        </div>
    );
};

export default FoldableGroup;
