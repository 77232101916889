import accounting from 'accounting';

export class Utilities {
    public static formatMoney(amount: string, includeSymbol?: boolean): string {
        if (includeSymbol) {
            return accounting.formatMoney(amount, '€ ', 2, '.', ',');
        } else {
            return accounting.formatMoney(amount, '', 2, '.', ',');
        }
    
    }
}
