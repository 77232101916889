import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'office-ui-fabric-react';
import { useCookies } from 'react-cookie';

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(['language']);
    const [language, setLanguage] = React.useState<string>(cookies && cookies.language || 'nl');

    React.useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    const changeLanguage = (value: string) => {
        var today = new Date();
        today.setFullYear(today.getFullYear() + 1);

        setLanguage(value);
        setCookie('language', value, { expires: today});
        i18n.changeLanguage(value);
    }

    return (
        <div className="language-selector">
            <span className={css('anchor-menu-item', { 'selected': language === "nl" })} onClick={() => changeLanguage('nl')}>NL</span>
            <div className="vl"></div>
            <span className={css('anchor-menu-item', { 'selected': language === "en" })} onClick={() => changeLanguage('en')}>EN</span>
        </div>
    )
}

export default LanguageSelector;