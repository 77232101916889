import { IResponseResult, IMolliPaymentInfo, IPaymentRequest } from '../type/index';

export default class PaymentApi {
    static GetPaymentInfo(request: IPaymentRequest) {
        let url = `${request.endpointUrl}/api/PaymentCors/GetPaymentLink/${request.hash}`;
        return fetch(url, {
            method: 'GET',
        }).then(response => {
            return response.json() as Promise<IResponseResult>;
        });
    }

    static updatePaymentInfo(request: IPaymentRequest) {
        let url = `${request.endpointUrl}/api/PaymentCors/updatepayment/${request.hash}/${request.transactionId}`;
        return fetch(url, { method: 'GET' }).then(response => {
            return response.json() as Promise<IResponseResult>;
        });
    }

    static PostPaymentInfo(request: IMolliPaymentInfo) {
        let url = `/api/payment/sendpayment`
        return fetch(url, {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            method: 'POST'
        }).then(response => {
            return response.json() as Promise<IResponseResult>;
        });
    }

    static getClientConfig() {
        return fetch(`/api/payment/config`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json() as Promise<IResponseResult>);
    }
}
